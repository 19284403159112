import { DependencyList, EffectCallback, useEffect } from 'react';

const DEFAULT_TIMEOUT = 10000; // 10 seconds

const usePeriodicEffect = (
    effect: EffectCallback,
    timeoutMs?: number,
    deps?: DependencyList
) => {
    const d: Array<any> = Object.assign([], deps ?? []);
    d.push(timeoutMs);
    useEffect(() => {
        const intervalId = setInterval(effect, timeoutMs ?? DEFAULT_TIMEOUT);
        effect();
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, d);
};

export default usePeriodicEffect;
