import moment from 'moment';
import {
    fetchCars,
    fetchExternalLocationsByString,
    fetchReservationsByCarID,
    getSubResource,
    postApiCall,
    doFetchCityBikeData,
    doCheckConcurrentReservationLimit,
} from '../../../../utilities/api';
import actions from './actions';
import appActions from '../../../duck/actions';
import { fetchReservationCompleted } from '../../ReservationsPage/duck/reservationReducers';
import {
    fetchGpsPosition,
    geoJsonToLatLng,
    handleApiCallAndParseData,
    momentToApiDateFormat,
    filterSearchFilters,
    checkIfFiltersAreActive,
} from '../../../../utilities/utils';
import { ENV } from '../../../App';
import {
    selectAvailabilityEndTime,
    selectAvailabilityReservedDays,
    selectAvailabilityStartTime,
    selectEndTime,
    selectFilters,
    selectFiltersAreActive,
    selectGpsPositionDisabled,
    selectHideUnavailableCars,
    selectKilometerEstimate,
    selectPreviouslySelectedLocations,
    selectSearchParams,
    selectSelectedCar,
    selectSelectedPosition,
    selectShowCityBikes,
    selectStartTime,
    selectSuperDamageWaiver,
} from './selectors';

import {
    selectAuthentication,
    selectSelectedMembership,
} from '../../../duck/selectors';
import { updateWhoAmIAndLogoutIfAuthExpired } from '../../../duck/operations';
import { fetchActiveReservations } from '../../ReservationsPage/duck/operations';
import { SYSTEM_DEFAULT_MAP_POSITION } from '../components/MapChooser/MapChooser';
import { wrapperBridgeExists } from '../../../../utilities/wrapperUtils';
import { createApiCallObject } from '../../../../utilities/apiUtils';
import {
    getInitializationEndTime,
    MAXIMUM_RESERVATION_LENGTH_DAYS,
} from '../../../../utilities/timeUtils';

const FALLBACK_POSITION_OBJECT_ID = 'fallback-position-object-id';

export const isDefaultPosition = (pos) =>
    pos.id === FALLBACK_POSITION_OBJECT_ID;

// Initializing search parameters
export function initSearch() {
    return (dispatch, getState) => {
        // Default end to end of day
        // let startTime = moment().second(0);
        // const endTime = startTime
        //     .hour(23)
        //     .minute(30)
        //     .second(0);

        // Default end to startTime + 3 hours
        const endTime = getInitializationEndTime();
        dispatch(actions.initializeSearch({ endTime }));

        let selectedPosition = selectSelectedPosition(getState());
        if (
            !selectedPosition ||
            selectedPosition.id === 'gps-position-object-id'
        )
            dispatch(getGpsPosition());
        else {
            dispatch(initMapPosition());
            dispatch(findCars());
        }
    };
}

// FOR TESTING TIMEOUT ERROR LOGGING
// function apiTimeoutTest() {
//     const testUrl = '/api/test/wait/7';
//     const request = {
//         headers: {
//             authorization: 'blapp',
//             'content-type': 'application/json'
//         },
//         method: `GET`
//     };
//     return dispatch => {
//         let promise = fetch(testUrl, request);
//         promise.issuer = 'apiTest';
//         promise.request = request;
//
//         handleApiCallAndParseData(
//             promise,
//             dispatch,
//             parsedData => {
//                 console.log('parsedData', parsedData);
//             },
//             error => {
//                 console.log(error);
//             }
//         );
//     };
// }

export function initSearchTime() {
    return (dispatch, getState) => {
        const end = getInitializationEndTime();
        dispatch(actions.updateTimes({ end }));
        updateData(dispatch, getState);
    };
}

/*
    Operations that call api functions
 */
export function getFilters() {
    return (dispatch, getState) => {
        if (!selectFilters(getState())) {
            dispatch(actions.fetchFiltersPending());
            return handleApiCallAndParseData(
                getSubResource(
                    selectAuthentication(getState()),
                    'search',
                    'filters',
                    createApiCallObject(dispatch, 'getFilters')
                ),
                dispatch,
                (parsedData) => {
                    dispatch(actions.fetchFiltersCompleted(parsedData));
                    dispatch(appActions.setSwapCarFilters(parsedData));
                },
                (error) => {
                    dispatch(actions.fetchFiltersError());
                    console.log(error);
                }
            );
        }
    };
}

export function findCars() {
    return (dispatch, getState) => {
        dispatch(actions.fetchCarsPending());
        let position = selectSelectedPosition(getState());
        if (!position) return dispatch(updateDefaultPosition());

        let searchParams = selectSearchParams(getState());
        dispatch(checkConcurrentReservationLimit());
        return handleApiCallAndParseData(
            fetchCars(
                searchParams.startTime,
                searchParams.endTime,
                position,
                filterSearchFilters(selectFilters(getState())),
                selectHideUnavailableCars(getState()) && '90',
                selectAuthentication(getState()),
                createApiCallObject(dispatch, 'findCars')
            ),
            dispatch,
            (parsedData) => {
                dispatch(actions.fetchCarsCompleted(parsedData));
                dispatch(actions.setShowingSwapCarOptions(false));
            },
            (error) => {
                dispatch(actions.fetchCarsError());
                console.log(error);
            }
        );
    };
}

export const checkConcurrentReservationLimit = (membershipArg) => {
    return (dispatch, getState) => {
        if (!navigator.onLine) return;
        dispatch(actions.checkConcurrentReservationLimitPending());
        let membership = membershipArg || selectSelectedMembership(getState());
        if (!membership) return;
        doCheckConcurrentReservationLimit(
            selectAuthentication(getState()),
            membership.id,
            selectStartTime(getState()),
            selectEndTime(getState()),
            createApiCallObject(dispatch, 'checkConcurrentReservationLimit')
        ).then(
            (res) =>
                res
                    .json()
                    .then((json) =>
                        dispatch(
                            actions.checkConcurrentReservationLimitCompleted(
                                json
                            )
                        )
                    ),
            (error) => {
                dispatch(actions.checkConcurrentReservationLimitError());
                console.error('checkConcurrentReservationLimit', error);
            }
        );
    };
};

export function fetchPriceOfCurrentCar(car, superDamageWaiver, kilometers) {
    return (dispatch, getState) => {
        dispatch(actions.fetchPriceOfCurrentCarPending());
        let startTime = selectStartTime(getState());
        let endTime = selectEndTime(getState());
        return handleApiCallAndParseData(
            postApiCall(
                selectAuthentication(getState()),
                'price',
                {
                    cars: [{ id: car.carId }],
                    start: startTime
                        ? startTime.format().substring(0, 19)
                        : moment().format().substring(0, 19),
                    end: endTime.format().substring(0, 19),
                    superDamageWaiver: superDamageWaiver,
                    kilometers: kilometers
                        ? kilometers
                        : selectKilometerEstimate(getState()),
                },
                createApiCallObject(dispatch, 'fetchPriceOfCurrentCar')
            ),
            dispatch,
            (parsedData) =>
                dispatch(
                    actions.fetchPriceOfCurrentCarCompleted(parsedData.cars[0])
                ),
            (error) => {
                dispatch(actions.fetchPriceOfCurrentCarError());
                console.log(error);
            }
        );
    };
}

export const setKilometerEstimate = (kilometers) => (dispatch) => {
    dispatch(actions.setKilometerEstimate(kilometers));
};

export function updateData(dispatch, getState) {
    dispatch(actions.updatingData());
    dispatch(findCars());
    if (selectSelectedCar(getState())) {
        dispatch(
            fetchPriceOfCurrentCar(
                selectSelectedCar(getState()),
                selectSuperDamageWaiver(getState())
            )
        );
    }
}

function isShorterThanMaximumDayLimit(period) {
    return (
        moment(period.end).diff(moment(period.start), 'days') <=
        MAXIMUM_RESERVATION_LENGTH_DAYS
    );
}

export function getAvailabilityInfo(
    carId,
    intervalStart,
    intervalEnd,
    optionalReservationId,
    optionalLocationId
) {
    return (dispatch, getState) => {
        dispatch(actions.fetchAvailabilityInfoPending());
        return handleApiCallAndParseData(
            fetchReservationsByCarID(
                carId,
                intervalStart,
                intervalEnd,
                0,
                0,
                selectAuthentication(getState()),
                createApiCallObject(dispatch, 'getAvailabilityInfo'),
                optionalReservationId,
                optionalLocationId
            ),
            dispatch,
            (parsedData) => {
                let availabilityInfo = {
                    isPeriodAvailable: false,
                    periods: [],
                };

                if (
                    parsedData &&
                    parsedData.cars &&
                    parsedData.cars[0] &&
                    parsedData.cars[0].carId === carId
                ) {
                    availabilityInfo.isPeriodAvailable = parsedData.cars[0].availability.find(
                        (period) => period.availabilityPercent === 100
                    );

                    let car = parsedData.cars[0];
                    // if (parsedData.cars.length > 1) {
                    //     console.log(parsedData.cars);
                    // }

                    let sortedPeriods = car.availability.sort((a, b) =>
                        moment(a).isBefore(moment(b)) ? -1 : 1
                    );
                    sortedPeriods
                        .filter(isShorterThanMaximumDayLimit)
                        .forEach((period) => {
                            if (period.state === 'AVAILABLE') {
                                /*
                             TODO
                             Det kan hende dette må inn igjen dersom vi plutselig begynner å se rare verdier
                             i tilgjengelighetslisten. Men har foreløpig utkommentert for å gjøre det mulig å velge
                             nå som fratidspunkt
                              */
                                // The period may contain strange start and end values such as 18.40
                                // Need to sanitize to make sure only 0 or 30 minutes are available
                                // period.start = sanitizeMinutes_roundUp(
                                //     period.start
                                // );
                                // period.end = sanitizeMinutes_roundDown(
                                //     period.end
                                // );
                                availabilityInfo.periods.push(period);
                            }
                        });
                }
                dispatch(
                    actions.fetchAvailabilityInfoCompleted(availabilityInfo)
                );
            },
            (error) => {
                dispatch(actions.fetchAvailabilityInfoError());
                console.log(error);
            }
        );
    };
}

export function getReservedDays(
    carId,
    startTime,
    endTime,
    optionalReservationId,
    optionalLocationId
) {
    return (dispatch, getState) => {
        dispatch(actions.fetchReservedDaysPending());
        return handleApiCallAndParseData(
            fetchReservationsByCarID(
                carId,
                startTime,
                endTime,
                1,
                1,
                selectAuthentication(getState()),
                createApiCallObject(dispatch, 'getReservedDays'),
                optionalReservationId,
                optionalLocationId
            ),
            dispatch,
            (parsedData) => {
                let reservedDays = [];
                let uniqueDays = [];

                if (
                    parsedData &&
                    parsedData.cars &&
                    parsedData.cars[0] &&
                    parsedData.cars[0].carId === carId
                ) {
                    parsedData.cars[0].availability.forEach((reservation) => {
                        if (reservation.state !== 'AVAILABLE') {
                            let startDate = moment(reservation.start)
                                .hours(0)
                                .minutes(0);
                            let endDate = moment(reservation.end);

                            if (endDate.format('HH:mm') === '00:00')
                                endDate = moment(endDate).subtract(
                                    1,
                                    'minutes'
                                );

                            endDate.hours(0).minutes(0);

                            if (startDate.isBefore(endDate, 'day')) {
                                reservedDays.push(startDate);
                                // Add dates between start and end-dates when reservation spans multiple days
                                enumerateDaysBetweenDates(
                                    reservedDays,
                                    startDate,
                                    endDate
                                );
                                reservedDays.push(endDate);
                            } else {
                                reservedDays.push(startDate);
                            }
                        }
                    });

                    uniqueDays = [
                        ...reservedDays
                            .reduce((reservedDays, day) => {
                                if (!reservedDays.has(day.format())) {
                                    reservedDays.set(day.format(), day);
                                }
                                return reservedDays;
                            }, new Map())
                            .values(),
                    ];
                }
                dispatch(actions.fetchReservedDaysCompleted(uniqueDays));
            },
            (error) => {
                dispatch(actions.fetchReservedDaysError());
                console.log(error);
            }
        );
    };
}

export function sendReservation(carId, superDamageWaiver) {
    return (dispatch, getState) => {
        dispatch(actions.bookingConfirmationPending());
        let startTime = selectStartTime(getState());
        let checkedStartTime = startTime ? startTime : moment();

        let selectedMembership = selectSelectedMembership(getState());
        if (!selectedMembership) {
            dispatch(actions.bookingConfirmationError('NO_MEMBERSHIP'));
            return;
        }
        const auth = selectAuthentication(getState());
        return handleApiCallAndParseData(
            postApiCall(
                auth,
                'reservations',
                {
                    membershipId: selectedMembership.id,
                    carId: carId,
                    start: momentToApiDateFormat(checkedStartTime),
                    end: momentToApiDateFormat(selectEndTime(getState())),
                    superDamageWaiver,
                },
                createApiCallObject(dispatch, 'sendReservation')
            ),
            dispatch,
            (parsedData) => {
                dispatch(fetchReservationCompleted(parsedData));
                dispatch(actions.bookingConfirmationCompleted());
                dispatch(fetchActiveReservations());
                // preLoadReservation(parsedData, auth);
                updateData(dispatch, getState);
            },
            (error) => {
                if (error === 'UNAUTHENTICATED') {
                    // Hvis reservasjonen skal fortsettes automatisk etter vellykket innlogging:
                    // dispatch(
                    //     setPerformAfterLogin(sendReservation(carId))
                    // );
                    dispatch(
                        actions.bookingConfirmationError('UNAUTHENTICATED')
                    );
                } else {
                    dispatch(actions.bookingConfirmationError());
                    console.log(error);
                }
            }
        );
    };
}

export function resetBookingConfirmationError() {
    return (dispatch) => {
        dispatch(actions.resetBookingConfirmationError());
    };
}

/*
    Operations that do not call api functions calls
 */

export function updateTimes(startTime, endTime) {
    return (dispatch, getState) => {
        dispatch(
            actions.updateTimes({
                start: startTime,
                end: endTime ? endTime : selectEndTime(getState()),
            })
        );
        updateData(dispatch, getState);
    };
}

export function setSelectedPosition(position) {
    return (dispatch) => {
        dispatch(actions.setSelectedPosition(position));
        dispatch(
            setMapPosition({
                latlng: geoJsonToLatLng(position.geojson),
                zoom: 15,
                accuracy: position.accuracy,
            })
        );
        dispatch(findCars());
    };
}

export const updateDefaultPosition = (overrideExisting, membership) => {
    return (dispatch, getState) => {
        let selectedPosition = selectSelectedPosition(getState());
        if (
            selectedPosition &&
            !overrideExisting &&
            !isDefaultPosition(selectedPosition)
        )
            return;
        try {
            if (!membership) membership = selectSelectedMembership(getState());
            if (!membership) {
                return dispatch(setDefaultSelectedPosition());
            }

            let defaultLocationString = membership.defaultLocation;
            let defaultLocation = JSON.parse(defaultLocationString);
            if (
                defaultLocation &&
                defaultLocation.name &&
                defaultLocation.coords
            ) {
                dispatch(setDefaultSelectedPosition(defaultLocation));
            } else {
                dispatch(setDefaultSelectedPosition());
            }
        } catch (e) {
            console.error('failed to get membership default location', e);
        }
    };
};

function setDefaultSelectedPosition(location) {
    return (dispatch) => {
        const coordinates = location?.coords || [
            SYSTEM_DEFAULT_MAP_POSITION.lng,
            SYSTEM_DEFAULT_MAP_POSITION.lat,
        ];
        dispatch(
            setSelectedPosition({
                geojson: {
                    type: 'Point',
                    coordinates,
                },
                id: FALLBACK_POSITION_OBJECT_ID,
                name: location?.name || 'Bergen',
            })
        );
        dispatch(findCars());
    };
}

const initMapPosition = () => (dispatch, getState) => {
    let selectedPosition = selectSelectedPosition(getState());
    if (!selectedPosition || !selectedPosition.geojson) {
        return;
    }
    const latlng = geoJsonToLatLng(selectedPosition.geojson);
    dispatch(setMapPosition({ latlng, zoom: 15 }));
};

export function setMapPosition(mapPosition) {
    return (dispatch) => {
        dispatch(actions.setMapPosition(mapPosition));
    };
}

export function setLocationSearchString(string) {
    return (dispatch) => {
        dispatch(actions.setLocationSearchString(string));
    };
}

export function addPreviouslySelectedLocation(location) {
    return (dispatch, getState) => {
        const filteredPreviouslySelectedLocations = selectPreviouslySelectedLocations(
            getState()
        ).filter((oldLocation) => oldLocation.id !== location.id);
        dispatch(
            actions.setPreviouslySelectedLocations([
                location,
                ...filteredPreviouslySelectedLocations.slice(0, 4),
            ])
        );
    };
}

let locationTimer;
export function fetchExternalLocations(searchTerm) {
    return (dispatch) => {
        clearTimeout(locationTimer);
        dispatch(actions.fetchExternalLocationsPending());

        locationTimer = setTimeout(() => {
            if (searchTerm.length === 0) {
                dispatch(actions.fetchExternalLocationsCompleted([]));
            } else {
                return handleApiCallAndParseData(
                    fetchExternalLocationsByString(
                        searchTerm,
                        createApiCallObject(dispatch, 'fetchExternalLocations')
                    ),
                    dispatch,
                    (parsedData) =>
                        dispatch(
                            actions.fetchExternalLocationsCompleted(
                                parsedData.features
                            )
                        ),
                    (error) => {
                        dispatch(actions.fetchExternalLocationsError());
                        console.log(error);
                    }
                );
            }
        }, 300);
    };
}

export function getGpsPosition(overrideGpsDisabled) {
    return (dispatch, getState) => {
        if (selectGpsPositionDisabled(getState()) && !overrideGpsDisabled)
            return dispatch(updateDefaultPosition(true));
        else if (selectGpsPositionDisabled(getState()) && overrideGpsDisabled)
            dispatch(actions.setGpsPositioningDisabled(false));

        let alertOnGpsFailure = !!selectSearchParams(getState())
            .selectedPosition;

        let onGpsError = (e) => {
            if (e.code === 1 || e.code === 2) {
                dispatch(actions.setGpsPositioningDisabled(true));
            }
            if (alertOnGpsFailure) gpsErrorAlert(e);
            dispatch(actions.gettingGpsPositionError());
            dispatch(updateDefaultPosition());
        };

        let onGpsSuccess = (result) => {
            dispatch(actions.gettingGpsPositionCompleted());
            dispatch(setSelectedPosition(result));
        };

        dispatch(actions.gettingGpsPositionPending());
        return ENV === 'production' || ENV === 'development'
            ? fetchGpsPosition().then(onGpsSuccess, onGpsError)
            : new Promise((resolve) => resolve(mockGpsPosition(dispatch)));
    };
}

const mockGpsPosition = (dispatch) => {
    dispatch(actions.gettingGpsPositionCompleted());
    dispatch(setDefaultSelectedPosition());
};

const gpsErrorAlert = (e) => {
    const permissionDeniedCode = 1;
    const positionUnavailableCode = 2;
    const timeoutExpiredCode = 3;

    if (e?.code) {
        if (e.code === permissionDeniedCode) {
            if (wrapperBridgeExists()) {
                console.warn('Missing gps permission in app.', e.message);
            } else {
                alert(
                    'Nettleseren din ga oss dessverre ikke tilgang til GPS posisjon. \n' +
                        'Du kan se i nettleserinnstillingene etter "tillat geolokasjon" eller lignende. \n' +
                        'Dette valget kan ofte finnes til venstre for adresselinjen.'
                );
            }
        } else if (e.code === timeoutExpiredCode) {
            alert(
                'Nettleseren din brukte dessverre litt for lang tid på å gi oss din GPS-posisjon. \n' +
                    'Forsøk å laste siden på nytt dersom du vil se biler som er nærmest din posisjon'
            );
        } else if (e.code !== positionUnavailableCode) {
            console.error('GPS error:', e.message);
        }
    }
};

export const setSuperDamageWaiver = (value) => (dispatch) =>
    dispatch(actions.setSuperDamageWaiver(value));

export function selectCar(car) {
    return (dispatch, getState) => {
        dispatch(updateWhoAmIAndLogoutIfAuthExpired());
        dispatch(actions.selectCar(car));
        dispatch(
            fetchPriceOfCurrentCar(car, selectSuperDamageWaiver(getState()))
        );
    };
}

export function updateFiltersAreActive(filters, hideUnavailableCars) {
    return (dispatch, getState) => {
        if (!filters) filters = selectFilters(getState());
        let filtersAreActive = !!checkIfFiltersAreActive(
            filters,
            hideUnavailableCars
        );
        let filtersWereActive = selectFiltersAreActive(getState());
        if (filtersAreActive !== filtersWereActive)
            dispatch(actions.setFiltersAreActive(filtersAreActive));
    };
}

let filterTimeout;
export function setFilters(filters) {
    return (dispatch, getState) => {
        if (filterTimeout) {
            clearTimeout(filterTimeout);
            filterTimeout = undefined;
        }
        dispatch(actions.setFilters(filters));
        dispatch(updateFiltersAreActive(filters));
        filterTimeout = setTimeout(() => {
            updateData(dispatch, getState);
            filterTimeout = undefined;
        }, 250);
    };
}

export function setHideUnavailableCars(hideUnavailableCars) {
    return (dispatch, getState) => {
        if (filterTimeout) {
            clearTimeout(filterTimeout);
            filterTimeout = undefined;
        }
        dispatch(actions.setHideUnavailableCars(hideUnavailableCars));
        dispatch(updateFiltersAreActive(undefined, hideUnavailableCars));
        filterTimeout = setTimeout(() => {
            updateData(dispatch, getState);
            filterTimeout = undefined;
        }, 250);
    };
}

export function clearFilters() {
    return (dispatch, getState) => {
        let newFilters = { ...selectFilters(getState()) };
        newFilters.groups = newFilters.groups.map((group) => {
            let newGroup = { ...group };
            newGroup.values = newGroup.values.map((value) => {
                let newValue = { ...value };
                newValue.selected = false;
                return newValue;
            });
            return newGroup;
        });
        newFilters.maxSeats = 9;
        newFilters.minSeats = 1;
        dispatch(setFilters(newFilters));
    };
}

export function initializeAvailability(
    carId,
    startTime,
    endTime,
    optionalReservationId,
    optionalLocationId
) {
    return (dispatch) => {
        dispatch(actions.initAvailabilityPending());

        dispatch(actions.setAvailabilityStartTime(startTime));
        dispatch(actions.setAvailabilityEndTime(endTime));

        let endReservationsInterval = moment().add(2, 'M').endOf('month');

        dispatch(
            getReservedDays(
                carId,
                moment(),
                endReservationsInterval,
                optionalReservationId,
                optionalLocationId
            )
        ).then(() => {
            dispatch(
                getAvailabilityInfo(
                    carId,
                    startTime,
                    endTime,
                    optionalReservationId,
                    optionalLocationId
                )
            ).then(() => {
                dispatch(actions.initAvailabilityCompleted());
            });
        });
    };
}

export function setAvailabilityStartTime(startTime) {
    return (dispatch) => {
        dispatch(actions.setAvailabilityStartTime(startTime));
    };
}

export function setAvailabilityEndTime(endTime) {
    return (dispatch) => {
        dispatch(actions.setAvailabilityEndTime(endTime));
    };
}

// TODO husk denne
export function updateAvailability(
    carId,
    optionalReservationId,
    optionalLocationId
) {
    return (dispatch, getState) => {
        let startTime = moment(selectAvailabilityStartTime(getState()));
        let endTime = selectAvailabilityEndTime(getState());

        if (
            selectAvailabilityReservedDays(getState()).some((day) =>
                day.isBetween(startTime, endTime, 'day', [])
            )
        ) {
            dispatch(
                getAvailabilityInfo(
                    carId,
                    selectAvailabilityStartTime(getState()),
                    selectAvailabilityEndTime(getState()),
                    optionalReservationId,
                    optionalLocationId
                )
            );
        } else {
            dispatch(
                actions.fetchAvailabilityInfoCompleted({
                    isPeriodAvailable: true,
                    periods: [],
                })
            );
        }
    };
}

const enumerateDaysBetweenDates = (dates, startDate, endDate) => {
    let currDate = moment(startDate).startOf('day');
    let lastDate = moment(endDate).startOf('day');

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone());
    }

    return dates;
};

export const fetchCityBikeData = () => (dispatch) =>
    doFetchCityBikeData()
        .then((response) => response.json())
        .then((json) => {
            if (json?.data?.bikeRentalStationsByBbox) {
                dispatch(
                    actions.setCityBikes(
                        json.data.bikeRentalStationsByBbox.filter(
                            (b) => `${b.networks}` === 'bergenbysykkel'
                        )
                    )
                );
            }
        });

export const toggleShowCityBikes = () => {
    return (dispatch, getState) => {
        const showCityBikes = !selectShowCityBikes(getState());
        dispatch(actions.setShowCityBikes(showCityBikes));
        if (showCityBikes) dispatch(fetchCityBikeData());
    };
};
