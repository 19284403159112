import { reloadWebView } from './wrapperUtils';
import usePeriodicEffect from './UsePeriodicEffect';

const reload = 'reload-tabs';
const newUpdateReady = 'update-ready';

const channel = new BroadcastChannel('update-channel');
channel.onmessage = (event: MessageEvent<string>) => {
    if (event?.data === reload && typeof window !== 'undefined') {
        window.location.reload();
    }
};

export function updateIsReady() {
    localStorage.setItem(newUpdateReady, 'true');
}
export function updateIsInstalled() {
    localStorage.removeItem(newUpdateReady);
}
export function isUpdateReady() {
    return localStorage.getItem(newUpdateReady) === 'true';
}

export function activateUpdate() {
    if (typeof window !== 'undefined' && window?.navigator?.serviceWorker) {
        window.navigator.serviceWorker.getRegistration().then((reg) => {
            // We mark the update as installed already here, to avoid infinite loops if anything goes wrong when activating it.
            updateIsInstalled();
            if (reg?.waiting) {
                // This message is caught in service-worker.js, and activates the new service worker immediately.
                reg.waiting.postMessage({ type: 'SKIP_WAITING' });
            } else reloadAllTabs();
        });
    } else console.error('No service worker available to activate the update.');
}

export function reloadAllTabs() {
    if (!reloadWebView()) {
        channel.postMessage(reload);
        if (typeof window !== 'undefined') window.location.reload();
    }
}

export function usePeriodicUpdateChecks() {
    usePeriodicEffect(() => {
        if (typeof window !== 'undefined' && window?.navigator?.serviceWorker) {
            window.navigator.serviceWorker.getRegistration().then((reg) => {
                // Even if we find a registration, the .update() method is not suppoerted on all platforms.
                //  If a user doesn't have the method, then we cannot force an update without the user
                //  reloading everything themselves.
                if (reg?.update) {
                    reg.update().catch((err) =>
                        console.error(
                            'Error when searching for service worker updates:',
                            err
                        )
                    );
                }
                if (reg?.waiting) {
                    updateIsReady();
                }
            });
        }
    }, 60 * 1000); // 1min
}
